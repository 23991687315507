<template>
    <div class="row mt-4">
        <div class="col-12 col-lg-8">
            <div class="d-middle border br-4 p-2 py-3" style="background-color: #FCFCFC ;">
                <div class="flex-fill">
                    <div class="d-middle mb-3">
                        <div class="mr-2 wh-24">
                            <i class="icon-account-checked-outline f-18" />
                        </div>
                        <p class="f-16 f-500">{{ data.nombre }} {{ data.apellido }}</p>
                    </div>
                    <div class="d-middle">
                        <div class="mr-2 wh-24">
                            <i class="icon-phone f-14" />
                        </div>
                        <p class="f-14 f-400 fr-light">{{ data.telefono }}</p>
                    </div>
                </div>
                <div class="d-middle">
                    <div class="mr-2 wh-24">
                        <el-tooltip content="Editar" effect="light" :visible-arrow="false" popper-class="border-0 shadow-sm rounded-pill">
                            <i class="icon-pencil-outline f-16 cr-pointer" @click="$emit('edit', data)" />
                        </el-tooltip>
                    </div>
                    <div class="mr-2 wh-24">
                        <el-tooltip content="Eliminar usuario" effect="light" :visible-arrow="false" popper-class="border-0 shadow-sm rounded-pill">
                            <i class="icon-delete-outline f-16 cr-pointer" @click="abrirEliminarUsuario" />
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </div>
        <modal-eliminar ref="refModalEliminarUsuario" titulo="Eliminar usuario" mensaje="¿Desea eliminar este usuario?" align-text="text-center" @eliminar="eliminarUsuario(data.id)" />
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        abrirEliminarUsuario(){
            this.$refs.refModalEliminarUsuario.toggle()
        },
        async eliminarUsuario(idContador){
            this.$emit('delete', idContador);
            this.$refs.refModalEliminarUsuario.toggle()
        },
    }
}
</script>
<style lang="">
</style>