<template>
    <section class="usuario-contador ml-4">
        <cargando v-if="cargando" />
        <p class="f-20 f-500 mb-4">Usuario contador</p>
        <div class="row">
            <div class="col-8">
                <p class="f-15 lh-18">
                    Crea un usuario para el contador o contadora encargado(a) de tu copropiedad. Este podrá gestionar todo lo relacionado con el módulo de <span class="text-general" style="color:#5A80EA">Pagos administración</span>. Ten en cuanta que solo puedes crear un usuario. 
                </p>
            </div>
        </div>
        <div class="row mt-2 mt-sm-4">
            <div class="col-12">
                <button class="btn btn-general h-32px w-136px f-14 f-300 px-3" @click="creando = true">
                    Crear usuario
                </button>
            </div>
        </div>
        <ValidationObserver v-slot="{ invalid }" ref="validation">
            <div v-if="creando" class="row mt-4">
                <div class="col-12 col-lg-8">
                    <div class="d-middle-bt mb-3">
                        <p class="f-20 f-500">{{ editando ? 'Editar usuario' : 'Nuevo usuario' }}</p>
                        <div class="d-middle-center close-form wh-24">
                            <i class="icon-close f-16 cr-pointer" @click="cerrarCreando" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <p class="f-12 pl-3">Nombre del contador(a)</p>
                            <ValidationProvider v-slot="{ errors }" rules="required|max:20" name="titulo" tag="div">
                                <el-input v-model="model.nombre" placeholder="Nombre" show-word-limit maxlength="20" size="small" class="w-100" />
                                <p class="text-danger f-10">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-lg-6">
                            <p class="f-12 pl-3">Apellido del contador(a)</p>
                            <ValidationProvider v-slot="{ errors }" rules="required|max:20" name="apellido" tag="div">
                                <el-input v-model="model.apellido" placeholder="Apellido" show-word-limit maxlength="20" size="small" class="w-100" />
                                <p class="text-danger f-10">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12 col-lg-6">
                            <p class="f-12 pl-3">Número telefónico</p>
                            <ValidationProvider v-slot="{ errors }" rules="required|numeric" name="telefono" tag="div">
                                <el-input v-model="model.telefono" placeholder="Número" size="small" class="w-100" />
                                <p class="text-danger f-10">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-lg-6">
                            <p class="f-12 pl-3">Correo</p>
                            <ValidationProvider v-slot="{ errors }" rules="required|email" name="correo" tag="div">
                                <el-input v-model="model.correo" placeholder="Correo" size="small" class="w-100" />
                                <p class="text-danger f-10">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12 col-lg-6">
                            <p class="f-12 pl-3">Contraseña</p>
                            <ValidationProvider v-slot="{errors}" :rules="{required:!editar,min:6,max:20}" name="Contraseña">
                                <el-input v-model="model.pass1" type="password" placeholder="Contraseña" size="small" class="w-100" />
                                <span class="text-danger f-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-lg-6">
                            <p class="f-12 pl-3">Repetir contraseña</p>
                            <ValidationProvider v-slot="{errors}" :rules="{required:!editar,min:6,max:15,confirmed:confirmedPassword()}" name="contraseña">
                                <el-input v-model="repetir" type="password" placeholder="Contraseña" size="small" class="w-100" />
                                <span class="text-danger f-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row justify-content-end mt-3">
                        <div class="col-auto">
                            <button v-if="creando" class="btn border bg-f5 f-14 w-94px f-300 px-3" @click="cerrarCreando">
                                Cerrar
                            </button>
                            <button v-if="creando" class="btn btn-general f-14 w-94px f-300 px-3 ml-3" @click="guardarUsuario">
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
        <div v-if="mostarCard">
            <card-usuario-contador v-for="(info, d) in data" :key="d" :data="info" @edit="editarUsuario" @delete="eliminarUsuarioContador" />
        </div>
    </section>
</template>
<script>
import Administracion from '~/services/pago_administracion'
import cardUsuarioContador from './components/cardUsuarioContador.vue'

export default {
    components: { cardUsuarioContador },
    data(){
        return{
            cargando : false,
            creando: false,
            editando: false,
            mostarCard: true,
            editar: false,
            repetir: '',
            model:{
                nombre: '',
                apellido: '',
                telefono: '',
                correo: '',
                pass1: '',
            },
            data:[]
        }
    },
    computed:{
    },
    mounted(){
        this.listarContador();
    },
    methods:{
        cerrarCreando(){
            this.creando = false;
            this.editando = false;
            this.mostarCard = true;
            this.limpiarFormulario();
        },
        limpiarFormulario(){
            this.model = {
                nombre: '',
                telefono: '',
                pass1: '',
            };
            this.repetir = '';
            this.editar = false;
        },
        editarUsuario(data){
            this.creando = true;
            this.editando = true;
            this.mostarCard = false;
            this.editar = true;
            this.model = data;
            this.model.pass1 = '********'
            this.repetir = '********'
        },
        async guardarUsuario(){
            try {
                if (this.editar){
                    this.editarUsuarioContador();
                }else{
                    let valid = await this.$refs.validation.validate();
                    if(!valid) return;

                    this.cargando = true;
                    const payload = {
                        nombre: this.model.nombre,
                        apellido: this.model.apellido,
                        telefono: this.model.telefono,
                        correo: this.model.correo,
                        password: this.model.pass1,
                    };

                    const { data } = await Administracion.postUserContador(payload);
                    if (data.success){
                        this.notificacion('','Guardado correctamente','success');
                        this.cerrarCreando();
                        this.listarContador();
                        this.mostarCard = true;
                    }
                }

            } catch (error){
                return this.errorCatch(error);
            } finally{
                this.cargando = false;
            }
        },
        abrirEliminarUsuario(){
            this.$refs.refModalEliminarUsuario.toggle()
        },
        eliminarUsuario(){
            this.$refs.refModalEliminarUsuario.toggle()
        },
        async listarContador(){
            try {
                this.cargando = true;

                const { data } = await Administracion.getUserContador();
                if (data.success){
                    this.data = data.data;
                    if (data.data.length === 0) this.mostarCard = false;
                }

            } catch (error){
                return this.errorCatch(error)
            } finally{
                this.cargando = false;
            }
        },
        confirmedPassword(){
            return this.model.pass1 != this.repetir ? true : false;
        },
        async eliminarUsuarioContador(idContador){
            try {
                const { data } = await Administracion.deletetUserContador(idContador);
                if (data.success){
                    this.notificacion('','Eliminado correctamente','success');
                    this.listarContador();
                    this.mostarCard = true;
                }
            } catch (error){
                return this.errorCatch(error);
            }
        },
        async editarUsuarioContador(){
            try {
                let valid = await this.$refs.validation.validate();
                if(!valid) return;

                this.cargando = true;
                const payload = {
                    idContador: this.model.id,
                    nombre: this.model.nombre,
                    apellido: this.model.apellido,
                    telefono: this.model.telefono,
                    correo: this.model.correo,
                };

                if (this.model.pass1 != '' && this.model.pass1 != '********') payload.password = this.model.pass1;

                const { data } = await Administracion.putUserContador(payload);
                if (data.success){
                    this.notificacion('','Actualizado correctamente','success');
                    this.cerrarCreando();
                    this.listarContador();
                    this.mostarCard = true;
                }
            } catch (error){
                return this.errorCatch(error);
            } finally{
                this.cargando = false;
            }
        },
    }
}
</script>
<style lang="scss" scoped>
</style>